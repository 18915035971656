
import { LitElement, html, css } from 'lit';

class CookiePopup extends LitElement {
  static properties = {
    isOpen: { type: Boolean, reflect: true, attribute: false },
    expire: { type: Number, reflect: true, attribute: false }
  }
  static styles = css`
    .cookie-consent {
      display: flex;
      pointer-events: auto;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 12px;
      color: #fff;
      background: rgba(0,0,0,.7);
      box-sizing: border-box;
      height: 0px;
      padding: 0px;

      visibility: visible;
      height: auto;
      padding: 1.2em;
    }
    .cookie-consent a {
      color: #fff !important;
    }
    .cookie-agree {
      color: #fff;
      background: dodgerblue;
      padding: .5em 1.5em;
    }
    .cookie-agree:hover {
      cursor: pointer;
    }

    .cc-hide {
      display: none;
    }
    @keyframes hide {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        visibility: hidden;
      }
    }
    /* メディアクエリ */
    @media(max-width: 576px) {
      .cookie-consent {
        flex-direction: column;
      }
      .cookie-text {
        margin-bottom: 1em;
      }
    }
  `
  constructor() {
    super();
    this.attachShadow({ mode: "open" });

    this.expire = 365;
    const flag = localStorage.getItem('popupFlag');

    if (flag != null) {
      const data = JSON.parse(flag);
      if (data['value'] == 'true') {
        this.isOpen = true;
      } else {
        const current = new Date();
        if (current.getTime() > data['expire']) {
          this.setWithExpiry('popupFlag', 'true');
          this.isOpen = true;
        }
      }
    } else {
      this.setWithExpiry('popupFlag', 'true');
      this.isOpen = true;
    }
    
  }
  setWithExpiry(key, value) {
    const current = new Date();
    const item = {
      value: value,
      expire: current.getTime() + this.expire * 24 * 3600 * 1000
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  agreeCookie() {
    this.isOpen = false;
    this.setWithExpiry('popupFlag', 'false');
  }
  render() {
    return this.isOpen ? html`
      <div class="cookie-consent">
        <div class="cookie-text">当サイトではCookieを使用します。</div>
        <div class="cookie-agree" @click="${this.agreeCookie}">OK</div>
      </div>
    ` : '';
  }
}
customElements.define('cookie-popup', CookiePopup);